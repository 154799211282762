signOnTasksRun.$inject = [
    '$rootScope', '$transitions', '$state', '$window', '$timeout', 'AUTH_EVENTS', 'SignOnTasks', 'StateUtils', 'AuthStatus'
];

export default function signOnTasksRun(
    $rootScope, $transitions, $state, $window, $timeout, AUTH_EVENTS, SignOnTasks, StateUtils, AuthStatus
) {
    const inTeamsApp = localStorage.getItem('msteams-deeplink');
    if (AuthStatus.isAuthenticated() && !inTeamsApp) {
        let taskObjects = $window.rnSession.user.signOnTasks;

        // Allow spectator to not have to deal with required tasks
        if ($window.rnSession.spectator) {
            taskObjects = $window.rnSession.user.signOnTasks.filter(task => !task.isRequired);
        }

        SignOnTasks.setTasks(taskObjects);

        if (userHasRequiredTasks()) {
            lockAppWithRequiredTasks();
        } else if(userHasOpntionalTasks()) {
            SignOnTasks.openOptionalTasksAfterLogin();
        }
    }

    $rootScope.$on(AUTH_EVENTS.LOGIN_SUCCESS, function(event, { user }) {
        SignOnTasks.setTasks(user.signOnTasks);
    });

    $rootScope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, function() {
        SignOnTasks.setTasks([]);
    });

    /**
     * On app load, if user has incomplete required sign on tasks, instead of proceeding to where they wanted to go
     * in the URL, redirect to the login page and open the required tasks and block going anywhere else until complete.
     */
    async function lockAppWithRequiredTasks() {
        let redirectState;
        if ($state.current.name && !$state.current.abstract) {
            redirectState = { name: $state.current.name, params: $state.params };
        } else {
            redirectState = StateUtils.getDefaultState();
        }

        // The $timeout is because $state.go doesn't work correctly in angular run functions for some reason
        // https://stackoverflow.com/questions/26355213/module-run-and-state-go-angularjs
        // May also be related ("Error: transition superseded"):
        // https://github.com/angular-ui/ui-router/issues/3246
        // https://github.com/angular-ui/ui-router/issues/3120
        await $timeout(0);
        await $state.go('login', { redirectTo: redirectState.name, redirectParams: redirectState.params });

        // Prevent state changes until user finishes required tasks.
        const deregisterPreventStateChange = $transitions.onStart({}, transition => transition.abort());
        $rootScope.$on(SignOnTasks.Events.TASKS_UPDATED, () => {
            if (!userHasRequiredTasks()) {
                deregisterPreventStateChange();
            }
        });

        const tasksSeries = SignOnTasks.lockAppWithRequiredTasks(null, { backdrop: 'static' });
        tasksSeries.once('complete', async () => {
            // Need "reload: true" because nav links don't load for some reason
            await $state.go(redirectState.name, redirectState.params, { reload: true });
            return SignOnTasks.openOptionalTasksAfterLogin();
        });
    }

    function userHasRequiredTasks() {
        return SignOnTasks.getRequiredTasks().length > 0;
    }

    function userHasOpntionalTasks() {
        return SignOnTasks.getOptionalTasks().length > 0;
    }
}
