AuthTimeoutInterceptor.$inject = ['$rootScope', '$q', '$injector', 'AUTH_EVENTS'];

export default function AuthTimeoutInterceptor($rootScope, $q, $injector, AUTH_EVENTS) {

    return {
        response,
        responseError
    };

    function response(res) {
        broadcastTimeoutEvent(res);
        return res;
    }

    function responseError(res) {
        broadcastTimeoutEvent(res);
        return $q.reject(res);
    }

    function teamsAppTimeoutDeeplink() {
        const deeplink = location.hash.substring(1);
        localStorage.setItem('msteams-deeplink', deeplink);
    }

    function broadcastTimeoutEvent(res) {
        const $state = $injector.get('$state'); // To avoid circular dependency problem

        switch (res.headers('Auth-Control')) {
            case 'session-refresh':
                $rootScope.$broadcast(AUTH_EVENTS.RESET_TIMEOUT);
                break;
            case 'session-timeout':
                $rootScope.$broadcast(AUTH_EVENTS.LOCK_APP);
                if (localStorage.getItem('msteams-deeplink')) {
                    teamsAppTimeoutDeeplink();
                }
                break;
            default:
                if (res.status === 401 && $state.current.name !== 'login') {
                    $rootScope.$broadcast(AUTH_EVENTS.LOCK_APP);
                    if (localStorage.getItem('msteams-deeplink')) {
                        teamsAppTimeoutDeeplink();
                    }
                }
        }
    }
}
