import _ from 'lodash';
import STATIC_REPORT_CATEGORIES from './STATIC_REPORT_CATEGORIES';


reportsListCtrl.$inject = ['$translate', 'Session', 'CustomReportFormModal', 'StateUtils'];

export default function reportsListCtrl($translate, Session, CustomReportFormModal, StateUtils) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            customReportsList: [],
            companyMetricsList: [],
        });

        const user = Session.getUser();
        const company = Session.getCompany();

        const customReports = {
            headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_CUSTOM_REPORTS', // 'Custom Reports'
            reports: vm.customReportsList
        };

        const metrics = {
            headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_METRICS', // 'Metrics'
            reports: vm.companyMetricsList.map(report => ({
                ...report,
                url: `/#/datafeed/${report.code}/hierarchy?filters=${report.mainDateColumn}--l30d`
            }))
        };

        const allReportCategories = Object.values(STATIC_REPORT_CATEGORIES).concat([customReports, metrics]);

        vm.categories = _(allReportCategories)
            .map(category => ({
                ...category,
                header: $translate.instant(category.headerTranslateKey),
                reports: _(category.reports)
                    .filter(report => !_.isFunction(report.isShow) || report.isShow({ company, user }))
                    .map(report => ({
                        ...report,
                        name: report.translateKey
                            ? $translate.instant(report.translateKey) : report.name,
                        description: report.descriptionTranslateKey
                            ? $translate.instant(report.descriptionTranslateKey) : report.description,
                        uiSref: report.url && StateUtils.getUiSrefFromUrl(report.url)
                    }))
                    .orderBy(['isSuggest', 'name'], ['desc', 'asc'])
                    .value()
            }))
            .filter(category => !_.isEmpty(category.reports))
            .orderBy(['header'])
            .value();

        vm.onCustomReportSelected = (report) => {
            CustomReportFormModal.open({ report });
        };
    };
}
