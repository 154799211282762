loginDialogCtrl.$inject = [
    '$rootScope', '$uibModalInstance', 'Session', 'AuthService', '$timeout',
    'AuthStateService', 'AUTH_EVENTS', 'LoginAsService', 'SsoService'
];

export default function loginDialogCtrl(
    $rootScope, $uibModalInstance, Session, AuthService, $timeout,
    AuthStateService, AUTH_EVENTS, LoginAsService, SsoService
) {
    const vm = this;

    const user = Session.getUser();
    const spectator = Session.getSpectator();
    const company = Session.getCompany();
    vm.inTeamsApp = localStorage.getItem('msteams-referrer') !== null;

    vm.teamsSSO = () => {
        const referrer = localStorage.getItem('msteams-referrer');
        const referrerSsoEndpoint = company.ssoEndpoints
            .find(ssoEndpoint => ssoEndpoint.referrerUrl && _.startsWith(referrer, ssoEndpoint.referrerUrl));
        window.parent.postMessage({ type: 'GET_LOCAL_STORAGE', key: 'Authorization' }, referrer);
        window.addEventListener('message', (event) => {
            if (!referrer.includes(event.origin)) {
                return; // Ignore messages from unexpected origins
            }

            if (event.data && event.data.type === 'LOCAL_STORAGE_DATA' && event.data.key === 'Authorization') {
                if (referrerSsoEndpoint) {
                    location.href = `${referrerSsoEndpoint.endpoint}?authToken=${event.data.value}`;
                } else {
                    console.error('No SSO endpoint found for referrer URL: ', referrer);
                }
            }
        }, false);
    };

    vm.alerts = [];

    vm.model = {
        username: spectator ? spectator.username : user.username,
        password: ''
    };

    vm.fields = [{
        key: 'username',
        type: 'customInput',
        templateOptions: {
            type: 'text',
            labelTranslateKey: 'app_SESSION_TIMEOUT_SIGN_IN_USERNAME_LABEL', // Username
            placeholderTranslateKey: 'app_SESSION_TIMEOUT_SIGN_IN_USERNAME_PLACEHOLDER', // Enter your username here
            required: true,
            labelClass: 'col-sm-3',
            controlClass: 'col-sm-9',
            static: true
        }
    }, {
        key: 'password',
        type: 'customInput',
        templateOptions: {
            type: 'password',
            labelTranslateKey: 'app_SESSION_TIMEOUT_SIGN_IN_PASSWORD_LABEL', // Password
            placeholderTranslateKey: 'app_SESSION_TIMEOUT_SIGN_IN_PASSWORD_PLACEHOLDER', // Enter password here
            labelClass: 'col-sm-3',
            controlClass: 'col-sm-9',
            autocomplete: false,
            required: true,
        }
    }];

    if (company.hasOption('CaptchaPreLogin')) {
        vm.fields.push({
            key: 'captchaToken',
            type: 'customCaptcha',
            templateOptions: {
                controlClass: 'col-sm-offset-3 col-sm-8',
            }
        });
    }

    vm.submitButtonOptions = {
        settings: {
            initial: {
                translateKey: 'app_SESSION_TIMEOUT_SIGN_IN_BUTTON', // Sign in
            },
            send: {
                translateKey: 'app_SESSION_TIMEOUT_SIGN_IN_BUTTON', // Sign in
            }
        }
    };

    vm.ssoEndpointSelected = SsoService.getSelectedEndpoint({ ignoreDirect: true });

    vm.submit = () => $timeout(async () => {
        await AuthService.login(vm.model);
        if (spectator) {
            await LoginAsService.loginAsContact({ contactID: user.userID });
        }
    });

    vm.onSubmitSuccess = () => {
        $rootScope.$broadcast(AUTH_EVENTS.UNLOCK_APP);
        $uibModalInstance.close();
    };

    vm.onSubmitFail = (err) => {
        alertError(err);
    };

    vm.backToLogin = () => AuthStateService.redirectAfterLogout();

    vm.problemSigningIn = (screen) => AuthStateService.redirectAfterLogout(screen);

    vm.closeAlert = (index) => {
        vm.alerts.splice(index, 1);
    };

    // TODO: duplicated from loginPanel.controller.js, figure out way to share code
    function alertError(err) {
        const message = err.data.returnCode ? {
            text: err.data.phrase
        } : {
            translateKey: 'app_LOGIN_DEFAULT_ERROR_MESSAGE' // 'Sorry, an error occurred'
        };
        // We want to hide the error ID when invalid SSO (1011) or invalid password (1012) because they're common.
        const hideErrorID = [1011, 1012].includes(err.data.returnCode);
        vm.alerts.unshift({
            type: 'danger',
            errorID: !hideErrorID && err.data.errorID,
            ...message
        });
        if (vm.alerts.length >= 3) {
            vm.alerts.pop();
        }
    }
}
