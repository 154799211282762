import _ from 'lodash';
import DataTable from 'datatables.net';
import moment from 'moment';

promoViewCtrl.$inject = [
    'promoDetails', 'PromoService', 'GaugeWidgetService', 'PageTitle', 'TrainingQuizService', 'Session',
    '$state', '$location', '$anchorScroll', '$filter', '$translate', '$document'
];

export default function promoViewCtrl(
    promoDetails, PromoService, GaugeWidgetService, PageTitle, TrainingQuizService, Session,
    $state, $location, $anchorScroll, $filter, $translate, $document
) {
    const vm = this;

    if (!promoDetails) {
        return;
    }

    const currentDate = moment().format('YYYY-MM-DD');
    const startDate = moment(promoDetails.startsOn).format('YYYY-MM-DD');
    const endDate = moment(promoDetails.endsOn).format('YYYY-MM-DD');
    const user = Session.getUser();

    vm.screen = 'view';
    vm.user = user;
    vm.promoDetails = promoDetails;
    vm.isAward = $state.current.data.isAward;
    vm.isTrainingQuiz = PromoService.isTrainingQuiz(promoDetails);
    vm.hasWidgetID = promoDetails.promoWidgetID !== 0;
    vm.showTargets = PromoService.isShowGauge(promoDetails);
    vm.showPoints = PromoService.isShowPoints(promoDetails, vm.hasWidgetID);
    vm.agreedToTermsOn = filterDate(promoDetails.agreedToTermsOn);
    vm.hasManagePromosPermission = vm.user.hasPermission('ManagePromos');
    
    vm.isTeamsApp = $document[0].querySelector('html').classList.contains('is-teams-app');

    vm.isPromoInactive = currentDate > endDate || currentDate < startDate; // checking if promo has ended to disable the form: adding css class { pointer-events: none; opacity: 0.4; }

    const firstQuizSectionQuestions = _.get(promoDetails, 'quiz.sections[0].questions');
    vm.showQuizForm = !vm.isTrainingQuiz && !_.isEmpty(firstQuizSectionQuestions);

    PageTitle.set(promoDetails.name);


    // iframe Link for embedded Code
    if (vm.promoDetails.isEmbeddedCode === 1) {
        vm.promoDetails.embeddedCodeiFrameURL = `/api/promos/${vm.promoDetails.ID}/embedded-code?languageCode=${vm.user.languageCode}`;
    }


    // Mission Game RN2
    vm.isMissionGame = PromoService.isMission(promoDetails);
    vm.missionClasses = vm.isMissionGame ? promoDetails.incentiveList
        .filter((incentive) => incentive.isTargetHit)
        .map((incentive) => `incentive-${incentive.incentiveID}-hit-target`)
        .join(' ') : '';

    if (vm.showTargets) {
        setStandingValues(promoDetails.widgetPromoTargets);
        setGaugeWidgetProgressValues();
    }

    if (vm.showPoints) {
        setStandingValues(promoDetails.widgetPointsEarned);
    }

    vm.widgetSize = vm.showTargets || vm.showPoints ? 'large' : 'small';

    const viewedPromos = user.viewedPromos;

    if (!viewedPromos.includes(vm.promoDetails.ID)) {
        viewedPromos.push(vm.promoDetails.ID);
        const userWithUpdatedViewedPromos = Object.assign(user, {
            viewedPromos
        });
        Session.setUser(userWithUpdatedViewedPromos);
    }

    vm.launchQuiz = () => TrainingQuizService.launchQuiz(promoDetails);

    function setStandingValues(widgetValues) {
        vm.standingValueName = widgetValues.standingValueName;
        if (widgetValues.standingValue) {
            vm.standingValue = widgetValues.standingValue.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            });
        } else {
            vm.standingValue = widgetValues.standingValue;
        }
        vm.pointsPending = widgetValues.pointsPending || 0;
        vm.updatedOn = widgetValues.updatedOn;
    }

    function setGaugeWidgetProgressValues() {
        // Assign values used to create the promoGaugeWidget
        const { valueToNextTarget, percentToNextTarget } = GaugeWidgetService
                        .calculateNextTargetDetails(promoDetails.widgetPromoTargets);
        vm.percentToNextTarget = percentToNextTarget;
        vm.valueToNextTarget = valueToNextTarget;
    }

    vm.onAcceptTerms = onAcceptTerms;
    async function onAcceptTerms() {
        const { agreedToTermsOn } = await PromoService.onAcceptTerms(vm.promoDetails.ID);
        vm.promoDetails.agreedToTermsOn = agreedToTermsOn;
        vm.agreedToTermsOn = filterDate(agreedToTermsOn);
    }

    vm.scrollToTerms = scrollToTerms;
    function scrollToTerms() {
        const termsID = 'promo-details-terms';
        if ($location.hash() !== termsID) {
            $location.hash(termsID);
        } else {
            $anchorScroll();
        }
    }

    function filterDate(date) {
        return $filter('date')(new Date(date), 'MMMM dd, yyyy');
    }

    $(document).ready(() => { // Wrap the sortTable function inside a $(document).ready() block
        sortTable();
    });

    function sortTable() {
        const dataTables = document.querySelectorAll('table.custom-sort-table');
        dataTables.forEach((dt) => {
            new DataTable(`#${dt.id}`, {
                responsive: true,
                autoWidth: false,
                language: {
                    decimal: "",
                    emptyTable: $translate.instant('promo_DATATABLE_NO_DATA'),
                    info: $translate.instant('promo_DATATABLE_SHOWING_ENTRIES'),
                    infoEmpty: $translate.instant('promo_DATATABLE_SHOWING_ENTRIES_EMPTY'),
                    infoFiltered: $translate.instant('promo_DATATABLE_INFO_FILTERED'),
                    infoPostFix: "", 
                    thousands: $translate.instant('promo_DATATABLE_THOUSANDS'),
                    lengthMenu: $translate.instant('promo_DATATABLE_LENGTH_MENU'),
                    loadingRecords: $translate.instant('promo_DATATABLE_LOADING'),
                    processing: "",
                    search: $translate.instant('promo_DATATABLE_SEARCH'),
                    zeroRecords: $translate.instant('promo_DATATABLE_ZERO_RECORDS'),
                    paginate: {
                        first: $translate.instant('promo_DATATABLE_PAGINATE_FIRST'),
                        last: $translate.instant('promo_DATATABLE_PAGINATE_LAST'),
                        next: $translate.instant('promo_DATATABLE_PAGINATE_NEXT'),
                        previous: $translate.instant('promo_DATATABLE_PAGINATE_PREVIOUS'),
                    },
                    aria: {
                        orderable: $translate.instant('promo_DATATABLE_ARIA_ORDERABLE'),
                        orderableReverse: $translate.instant('promo_DATATABLE_ARIA_ORDERABLE_REVERSE')
                    }
                }
            });
        });
    }
}
