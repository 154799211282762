loginDialogCtrl.$inject = ['$window'];

export default function loginDialogCtrl($window) {
    const vm = this;
    vm.reload = reload;
    
    function reload() {
        const referrer = localStorage.getItem('msteams-referrer');
        if (referrer) {
            const referrerSsoEndpoint = company.ssoEndpoints
            .find(ssoEndpoint => ssoEndpoint.referrerUrl && _.startsWith(referrer, ssoEndpoint.referrerUrl));
            window.parent.postMessage({ type: 'GET_LOCAL_STORAGE', key: 'Authorization' }, referrer);
            window.addEventListener('message', (event) => {
                if (!referrer.includes(event.origin)) {
                    return; // Ignore messages from unexpected origins
                }

                if (event.data && event.data.type === 'LOCAL_STORAGE_DATA' && event.data.key === 'Authorization') {
                    if (referrerSsoEndpoint) {
                        location.href = `${referrerSsoEndpoint.endpoint}?authToken=${event.data.value}`;
                    } else {
                        console.error('No SSO endpoint found for referrer URL: ', referrer);
                    }
                }
            }, false);
        } else {
            $window.location.reload();
        }
    }
}
